<template lang="pug">
.banner-style
  div {{ message }}
  a(:href="url") Visit Now
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      message:
        "We've developed a new website, which you can start using now before it officially replaces the current one.",
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    url() {
      if (!this.biz) return "https://next.selflane.com";
      return "https://next.selflane.com/bizs/" + this.biz?.url;
    },
  },
};
</script>

<style lang="sass" scoped>
.banner-style
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  gap: 10px
  background-color: #f5f5f5
  color: #333
  padding: 10px
  font-size: 0.9em
  font-weight: 400
  border-bottom: 1px solid #ccc
  a
    display: inline-block
    text-transform: none
    // single line
    white-space: nowrap
</style>