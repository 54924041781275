import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=020e17ae&scoped=true&lang=pug"
import script from "./Page.vue?vue&type=script&lang=js"
export * from "./Page.vue?vue&type=script&lang=js"
import style0 from "./Page.vue?vue&type=style&index=0&id=020e17ae&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__lodash@4.17.21__lkjqg4lemhx3tcnq4xpodjyioi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020e17ae",
  null
  
)

export default component.exports