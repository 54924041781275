import { render, staticRenderFns } from "./BottomCartSheet.vue?vue&type=template&id=60e7f6ba&scoped=true&lang=pug"
import script from "./BottomCartSheet.vue?vue&type=script&lang=js"
export * from "./BottomCartSheet.vue?vue&type=script&lang=js"
import style0 from "./BottomCartSheet.vue?vue&type=style&index=0&id=60e7f6ba&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__lodash@4.17.21__lkjqg4lemhx3tcnq4xpodjyioi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e7f6ba",
  null
  
)

export default component.exports