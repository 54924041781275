import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=76d95ec7&scoped=true&lang=pug"
import script from "./Message.vue?vue&type=script&lang=js"
export * from "./Message.vue?vue&type=script&lang=js"
import style0 from "./Message.vue?vue&type=style&index=0&id=76d95ec7&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_css-loader@6.11.0_webpack@5.96.1__lodash@4.17.21__lkjqg4lemhx3tcnq4xpodjyioi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76d95ec7",
  null
  
)

export default component.exports